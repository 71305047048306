<template>
  <div>
    <van-nav-bar
        title="详细信息"
        left-text="返回"
        right-text="下载"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
    />

    <el-table
        :data="tableData"
        stripe
        id="table"
        >
      <el-table-column
          prop="vol"
          label="期数"
          >
      </el-table-column>
      <el-table-column
          prop="money"
          label="月供（元）"
          >
      </el-table-column>
      <el-table-column
          prop="capital"
          label="月供本金（元）">
      </el-table-column>
      <el-table-column
          prop="rate"
          label="月供利息（元）">
      </el-table-column>
      <el-table-column
          prop="new_capital"
          label="剩余本金（元）">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { NavBar } from 'vant';
import XLSX from 'xlsx';

export default {

  name: "Info",
  components: {
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      tableData: [],
      head: {}
    }
  },

  methods: {

    // 返回键功能
    onClickLeft() {
      this.$router.push({path:'/', name: 'calculator'});
    },

    // 下载键功能
    onClickRight() {
      this.export();
    },

    // 下载Excel表格
    export() {
      let table = document.getElementById('table');
      let worksheet = XLSX.utils.table_to_sheet(table);
      let workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet');
      workbook = XLSX.utils.table_to_book(document.getElementById('table'))
      try {
        XLSX.writeFile(workbook, '贷款明细.xlsx');
      } catch (e) {
        console.log(e, workbook);
      }

//       let table = document.getElementById('table');
//       let worksheet = XLSX.utils.table_to_sheet(table);
//       let workbook = XLSX.utils.book_new();
//       XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet');
// // 以上四行也可以直接一行搞定，如果不需要对表格数据进行修改的话
//       workbook = XLSX.utils.table_to_book(document.getElementById('table'))
//       let workboodout = XLSX.write(workbook, { bookType: 'xlsx', bookSST: true, type: 'array'});
//       try {
//         FileSaver.saveAs(new Blob([workboodout], { type: 'application/octet-stream'}), 'table.xlsx');
//         // table.xlsx 为导出的文件名，可自定义
//       } catch (e) {
//         console.log(e, workboodout);
//       }
//       return workboodout;

    }
  },

  // 页面初始化
  created() {
    this.head = this.$store.state.head
    this.tableData = this.$store.state.table_data;
  }
};
</script>

<style lang="less" scoped>
</style>